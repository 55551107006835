import React, { useState, useEffect, useContext } from 'react';
import { Form, FormControl, FormGroup, FormLabel, FormSelect, Modal, Spinner } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import moment from "moment";
import { Animated } from "react-animated-css";
import { Scrollbars } from "react-custom-scrollbars-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';

//API
import { TIME_SLOT } from "../../../gql/Query";
import { CANCELLATION_REQUEST, TIME_SLOT_AVAILABILITY } from "../../../gql/Mutation";



const RescheduleDateTimeModal = ({ bID, onAdd, checkAuth, closeDays, closeDate, blockedTime,
    canList, vetting, getVetDateTime, formatServices, notification, shopSlotDuration, reschedulePricingId }) => {
    const [waiting, setWaiting] = useState(false);
    // if (blockedTime) {
    //     console.log("blockedTime", blockedTime)
    // }
    // console.log(vetting)
    const today = new Date();
    const [closeDateIndex, setCloseDateIndex] = useState([]);
    const [weekday, setWeekday] = useState(moment(today).format('DD-MM-YYYY'));
    const [timeshow, setTimeshow] = useState("");
    const [tempDate, setTempDate] = useState("");
    const [date, setDate] = useState(moment(today).format('DD-MM-YYYY'));
    const [timeAPI, setTimeAPI] = useState([{}]);
    const [block, setBlock] = useState({});
    const [time, setTime] = useState("");
    const [groupDates, setGroupDates] = useState([{}]);
    // console.log("closeDays", closeDays)

    //---------------------------select time--------------------------
    const { data, loading, refetch } = useQuery(TIME_SLOT, {
        variables: {
            business_id: parseInt(bID),
            date: weekday,
            services: JSON.stringify({service:[reschedulePricingId]})
        },
        fetchPolicy: "network-only"
    });

    //----------------checking for time slot availity------------------
    const [timeslot_availability] = useMutation(TIME_SLOT_AVAILABILITY);

    //-----------------------CANCELLATION REQUEST---------------
    const [cancellation_request] = useMutation(CANCELLATION_REQUEST);
    const wait = () => {
        cancellation_request({
            variables: {
                date: weekday,
                guest_id: 0,
                business_id: parseInt(bID)
            }
        })
            .then(({ data }) => {
                //console.log(data.cancellation_request)
                if (data.cancellation_request.status === 1) {
                    canList();
                    toast.success(data.cancellation_request.message);
                } else {
                    toast.error(data.cancellation_request.message);
                }
            })
            .catch((e) => {
                console.log(e)
                toast.error("Internal server error!");
            })
    }

    //--------------filter all the closed weekday---------------
    const isWeekday = (val) => {
        var active
        if (closeDate?.length > 0) {
            for (let cd of closeDate) {
                if (moment.unix(cd.date).format("DD/MM/YYYY") === moment(val).format("DD/MM/YYYY")) {
                    active = false;
                    break;
                } else {
                    if (closeDateIndex.length === 0) {
                        active = true;
                    } else {
                        const day = new Date(val).getDay();
                        for (let cDate of closeDateIndex) {
                            if (cDate === day) {
                                active = false;
                                break;
                            } else {
                                active = true;
                            }
                        }
                    }
                }
            }
        } else {
            if (closeDateIndex.length === 0) {
                active = true;
            } else {
                const day = new Date(val).getDay();
                for (let cDate of closeDateIndex) {
                    if (cDate === day) {
                        active = false;
                        break;
                    } else {
                        active = true;
                    }
                }
            }
        }
        return active
    };
    //------------------service date time in local storage---------------
    const setWithExpiry = (key, value) => {
        const now = new Date();
        const item = {
            value: value,
            expiry: now.getTime() + 60 * 60 * 1000,
        }
        localStorage.setItem(key, JSON.stringify(item));
    }
    const getWithExpiry = (key) => {
        const itemStr = localStorage.getItem(key)
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return null
        }
        return item.value
    }

    //-----------select date---------------------
    const selectDate = (val) => {
        setDate(moment(val).format('DD-MM-YYYY'));
        // console.log(moment(val).format('YYYY-MM-DD'), getWithExpiry("serviceDate"))
        // console.log("vetting", moment(moment(val).format('YYYY-MM-DD')).isAfter(getWithExpiry("serviceDate")))
        if (vetting) {
            setTempDate(val);
            if (moment(moment(val).format('YYYY-MM-DD')).isAfter(getWithExpiry("serviceDate"))) {
                // toast.error("You must have to select consultation call time before the time you have selected for the services!");
                setTimeAPI();
            } else {
                setWeekday(moment(val).format('DD-MM-YYYY'));
                setTimeout(() => {
                    refetch()
                }, 1000);
            }
        } else {
            setWeekday(moment(val).format('DD-MM-YYYY'));
            setWithExpiry("serviceDate", val);
            setTimeout(() => {
                refetch()
            }, 1000);
        }

        //-------------for close dates-------------------
        for (let bDate of blockedTime) {
            if (moment.unix(bDate.date).format("DD/MM/YYYY") ===
                moment(val).format("DD/MM/YYYY")) {
                setWaiting(true)
                break;
            } else {
                setWaiting(false)
            }
        }
    }

    //--------------------select time-------------------
    const changeTime = (val) => {
        if (notification) {
            let t = moment.unix(val).utcOffset('+0000').format("h:mm a")
            setTime(t);
        } else {
            if (vetting) {
                const x = parseInt(getWithExpiry("serviceTime"));
                const y = val;
                // console.log(x, y)
                if (moment(moment(tempDate).format('YYYY-MM-DD')).isSame(getWithExpiry("serviceDate"))) {
                    if (y >= x) {
                        // toast.error("You must have to select vetting call time before the time you have selected for the services!");
                    } else {
                        let t = moment.unix(val).utcOffset('+0000').format("h:mm a");
                        setTime(t);
                        getVetDateTime(t, date);
                    }
                } else {
                    let t = moment.unix(val).utcOffset('+0000').format("h:mm a")
                    getVetDateTime(t, date);
                }
            } else {
                setTime(moment.unix(val).utcOffset('+0000').format("h:mm a"));
                setWithExpiry("serviceTime", val);
            }
        }
    }

    const checkAvailability = () => {
        timeslot_availability({
            variables: {
                date: date,
                time: time,
                business_id: parseInt(bID),
                services: notification ? formatServices : formatServices()
            }
        })
            .then(({ data }) => {
                if (data?.timeslot_availability?.status === 1) {
                    checkAuth(time, date);
                } else {
                    toast.info(data?.timeslot_availability?.message)
                }
            })
            .catch(e => {
                toast.error("Internal server error!")
                console.log("error", e);
            })
    }

    useEffect(() => {
        const weekDay = ["sunday", "monday", "tuesday", "wednesday",
            "thursday", "friday", "saturday"];
        var dayArr = []
        if (data?.business_time_slot?.time_slots) {
            setTimeAPI(data.business_time_slot?.time_slots)
            setTimeshow(true)
        }
        if(data?.business_time_slot?.date_slots){
            setGroupDates(data.business_time_slot?.date_slots?.map(itm => new Date(moment.unix(itm.s_date))))
        }
        if (loading) {
            setTimeshow(false)
        }
        if (closeDays) {
            closeDays.map((x) => (
                dayArr.push(weekDay.indexOf(x.weekday))
            ))
            setCloseDateIndex(dayArr);
        }
        // if (closeDate) {
        //     console.log("closeDays", closeDate)
        //     setBlock(closeDate.map(itm => new Date(moment.unix(itm.date))))
        //     // blockedTime.map(x => console.log(new Date(moment.unix(x.date))))
        //     //console.log("blockedTime", new Date(itm?.date))
        // }
        if (blockedTime) {
            setBlock(blockedTime.map(itm => new Date(moment.unix(itm.date))))
        }
    }, [data, loading, closeDays, closeDate, blockedTime])

    return (
        <>
            {waiting ?
                <>
                    {!vetting &&
                        <Modal.Header closeButton>
                            <Modal.Title>Wait in the cancellation list</Modal.Title>
                        </Modal.Header>
                    }
                    <Modal.Body>
                        <div className="text-center">
                            <h2> All the schedules are occupied! </h2>
                            <p>
                                If any schedule gets free, do you want us to send email notification to you?
                            </p>
                            <div className="d-flex justify-content-center venuItem">
                                <button className='btn venuBtn me-2' onClick={() => { wait() }}>Yes</button>
                                <button className='btn venuBtn ms-2' onClick={() => { setWaiting(false) }}>No</button>
                            </div>
                        </div>
                    </Modal.Body>
                </>
                : <>
                    {!vetting &&
                        <Modal.Header closeButton>
                            <Modal.Title>Select date and time</Modal.Title>
                        </Modal.Header>
                    }
                    <Modal.Body>
                        <div className='d-flex justify-content-center custom-datepicker mx-auto'>

                            { groupDates?.length > 0 ? 
                                <DatePicker
                                    inline
                                    dateFormat="yyyy/MM/dd"
                                    selected={today}
                                    onChange={selectDate}
                                    minDate={today}
                                    includeDates={groupDates}
                                    highlightDates={block}
                                />: 
                                <DatePicker
                                    inline
                                    dateFormat="yyyy/MM/dd"
                                    selected={today}
                                    onChange={selectDate}
                                    minDate={today}
                                    filterDate={isWeekday}
                                    highlightDates={block}
                                />
                            }
                            
                            <div>
                                <div className='text-center font-chzday chuzy-time react-datepicker__header'>Time</div>
                                <div className='available-time'>
                                    <Scrollbars>
                                        {loading &&
                                            <div className='d-flex justify-content-center'>
                                                <Spinner animation="border" />
                                            </div>
                                        }
                                        {timeshow && timeAPI?.length > 0 &&
                                            <Animated animationIn="fadeInDown" animationOut="fadeInDown" isVisible={true} animationInDelay={300}>
                                                {timeAPI.map((x) => (
                                                    <div key={x.s_time} className="d-flex align-items-center">
                                                        <input className='form-check-input' type="radio" id={x.s_time} name="time" onChange={() => { changeTime(x.s_time) }} />
                                                        <label htmlFor={x.s_time}>{moment.unix(x.s_time).utcOffset('+0000').format("h:mm a")}</label>
                                                    </div>
                                                ))}
                                            </Animated>
                                        }
                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {vetting && notification &&
                        <div className="select-time-btn-wrap venuItem pb-2">
                            <button className="btn primaryBtn"
                                onClick={() => { checkAuth(time, date) }}
                                disabled={(date && time !== "") ? false : true}>
                                Confirm
                            </button>
                        </div>
                    }
                    {!vetting &&
                        <div className="select-time-btn-wrap venuItem pb-2">
                            <button className="btn primaryBtn"
                                onClick={checkAvailability}
                                disabled={(date && time !== "") ? false : true}>
                                Next <i className="arrow fa fa-angle-right" />
                            </button>
                        </div>
                    }
                </>}
        </>
    )
}

export default RescheduleDateTimeModal
