import { useMemo } from 'react';

const useCartRawData = (cartItems, person) => {
    const formattedServices = useMemo(() => {
        const servicePriceIDList = [];
        const voucherPriceIDList = [];
        if (cartItems) {
            cartItems.forEach((x) => {
                if (x.__typename === "ServicePricing") {
                    servicePriceIDList.push(x);
                } else {
                    voucherPriceIDList.push({ id: x.id, qty: x.qty, name: person });
                }
            });
        }
        const totalIDList = {
            service: servicePriceIDList,
            voucher: voucherPriceIDList,
        };
        return totalIDList;
    }, [cartItems]);

    return formattedServices;
};

export default useCartRawData;
