import React from 'react';
import { Scrollbars } from "react-custom-scrollbars-2";
import { totalPayable } from '../../../system/utility';

const SelectedService = ({
    cartItems,
    serviceAmount,
    voucherAmount,
    totalServiceAmount,
    totalSpecialUpfront,
    upfront,
    currency,
    guestStatus,
    vettingCall,
    onlyGroup
}) => {
    return (
        <>
            {
                upfront > 0 &&
                ((guestStatus === "New" && vettingCall) ?
                    <p className='bg-light p-3'>
                        Your Selected Service, Date and Time have been saved. The Deposit will be payable after the Video Consultation Call. If you are purchasing a Voucher, payment for the Voucher will be required in Full after the Video Consultation Call. Thank You!
                    </p> :
                    <p className='bg-light p-3'>
                        Thank You for using Chuzeday.
                        To secure your Appointment booking, please complete the Deposit Payment.
                        If your are buying a Voucher, please complete the Full Payment
                    </p>
                )
            }
            <div className='calendar-wrap bg-light'>
                {
                    cartItems?.length > 3 ?
                        <Scrollbars style={{ height: "250px", width: "100%" }}>
                            {
                                cartItems?.map((item, i) => (
                                    <div key={i}>
                                        {
                                            item.__typename === "ServicePricing" ?
                                                <div className="SubCategory row mx-0 bg-white mb-2 px-2">
                                                    <div className="col-6 SubCategory">
                                                        <div className="name">
                                                            <span>{item?.service_name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 SubCategory">
                                                        <div className="price">
                                                            <span>{`${currency}${item?.special_price !== 0 ? item?.special_price : item?.price}`}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 SubCategory">
                                                        <div className="time">
                                                            <span>{item.duration} min</span>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 text-left'>
                                                        <p className='text-dark show-appt-date text-left'>Appointment on {item.date}, at {item.time}</p>
                                                    </div>
                                                </div> :
                                                <div className="SubCategory row mx-0 bg-white mb-2 px-2">
                                                    <div className="col-6 SubCategory">
                                                        <div className="name">
                                                            <span>{item?.name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 SubCategory">
                                                        <div className="price">
                                                            <span>{currency}{item.retail} </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 SubCategory">
                                                        <div className="time">
                                                            <span>{item.valid_for}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                ))
                            }
                        </Scrollbars> :
                        cartItems?.map((item, i) => (
                            <div key={i}>
                                {
                                    item.__typename === "ServicePricing" ?
                                        <div className="SubCategory row mx-0 bg-white mb-2 px-2">
                                            <div className="col-6 SubCategory">
                                                <div className="name">
                                                    <span>{item?.service_name}</span>
                                                </div>
                                            </div>
                                            <div className="col-3 SubCategory">
                                                <div className="price">
                                                    <span>{`${currency}${item?.special_price !== 0 ? item?.special_price : item?.price}`}</span>
                                                </div>
                                            </div>
                                            <div className="col-3 SubCategory">
                                                <div className="time">
                                                    <span>{item.duration} min</span>
                                                </div>
                                            </div>
                                            <div className='col-12 text-left'>
                                                <p className='text-dark show-appt-date text-left'>Appointment on {item.date}, at {item.time}</p>
                                            </div>
                                        </div> :
                                        <div className="SubCategory row mx-0 bg-white mb-2 px-2">
                                            <div className="col-6 SubCategory">
                                                <div className="name">
                                                    <span>{item?.name}</span>
                                                </div>
                                            </div>
                                            <div className="col-3 SubCategory">
                                                <div className="price">
                                                    <span>{currency}{item.retail} </span>
                                                </div>
                                            </div>
                                            <div className="col-3 SubCategory">
                                                <div className="time">
                                                    <span>{item.valid_for}</span>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        ))
                }
                <div className="d-flex justify-content-around text-dark font-chzday text-start mt-5">
                    {
                        voucherAmount > 0 &&
                        <div className="pe-2">
                            <h3>Voucher:</h3>
                            <p className='fw-bolder'>
                                Payable: {currency}{voucherAmount}
                            </p>
                        </div>
                    }
                    {
                        serviceAmount > 0 &&
                        <div className="ms-5 pe-2">
                            <h3>Service:</h3>
                            <p>
                                Total price: {currency}{totalServiceAmount}
                            </p>
                            {upfront > 0 &&
                                <p>
                                    Deposit Payment {upfront}% : {currency}{`${totalPayable(serviceAmount, upfront)}`}
                                </p>
                            }
                            {+totalSpecialUpfront > 0 &&
                                <p>
                                    Special Deposit Payment : {currency}{`${+totalSpecialUpfront}`}
                                </p>
                            }
                            {/* {upfront > 0 &&
                                <p className='fw-bolder'>
                                    Payable: {currency}{`${totalPayable(serviceAmount, upfront)}`}
                                </p>
                            } */}
                        </div>
                    }
                </div>
                {
                    <div className="mx-auto col-md-10 mt-5">
                        <h2 className='fw-bolder'>Total Payable: {voucherAmount + +totalPayable(serviceAmount, upfront) + +totalSpecialUpfront}</h2>
                    </div>
                }
                <h6 className='text-center mt-5'>*vat included</h6>
            </div>
        </>
    )
}

export default SelectedService
