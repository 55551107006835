const usePrice = (cartItems) => {
    var price = 0;
    var sp_price = 0;
    var service_price = 0;
    var serviceAmount = 0;
    var voucherAmount = 0;
    var updatedTotalAmount = 0;
    var totalSpecialUpfront = 0;
    var totalServiceAmount = 0;
    if (cartItems) {
        cartItems.map((x) => {
            if (!x.retail) {
                // console.log("sp_price, x.special_price", sp_price, x.special_price, x.special_deposit)

                //console.log("sp_price", sp_price);
                if (x.special_price === 0) {
                    price += x.price
                    sp_price = sp_price + x.price;
                    service_price = service_price + x.price;
                    totalServiceAmount += x.price;
                    if (x.special_deposit > 0) {
                        totalSpecialUpfront = parseFloat(totalSpecialUpfront) + parseFloat((x.price * x.special_deposit / 100).toFixed(2));
                    } else {
                        serviceAmount += x.price;
                    }
                } else {
                    price += x.price;
                    sp_price = sp_price + x.special_price;
                    service_price = service_price + x.special_price;
                    totalServiceAmount += x.special_price;
                    if (x.special_deposit > 0) {
                        totalSpecialUpfront = parseFloat(totalSpecialUpfront) + parseFloat((x.special_price * x.special_deposit / 100).toFixed(2));
                    } else {
                        serviceAmount += x.special_price;
                    }
                }
            } else {
                // console.log("sp_price, x.retail", sp_price, x.retail)

                updatedTotalAmount = x.retail * x.qty;
                sp_price += updatedTotalAmount;
                voucherAmount += updatedTotalAmount;

                // console.log(x)
                // sp_price = sp_price + x.retail
            }
        })
    }
    return {
        price,
        sp_price,
        service_price,
        serviceAmount,
        voucherAmount,
        updatedTotalAmount,
        totalSpecialUpfront,
        totalServiceAmount
    }
}

export default usePrice
