import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from '@apollo/client';
import demoService from '../../../dist/images/Services/customer-banner.png';
import moment from 'moment';
import { toast } from 'react-toastify';
import logo from '../../../dist/images/logo.svg';
import GetCurrency from "../widgets/GetCurrency";
import MetaGenerator from '../widgets/MetaGenerator';
import RevolutPayment from "../SingleShop/components/ShopServices/RevolutPayment";
import { Modal } from "react-bootstrap";
import Stripe from '../../../dist/images/PayGateways/stripe.png';
import Revolut from '../../../dist/images/PayGateways/revolut.png';

//API
import { INVOICE } from "../../../gql/Query";
import { MAKE_PAYMENT, REVOLUT_PUBLIC_ID } from "../../../gql/Mutation";

const Invoice = () => {
    const [country, setCountry] = useState("");
    const [currency, setcurrency] = useState("");
    const imageBaseURL = "https://chuzeday.com/uploads/businessPhoto/";
    const [invoiceInfo, setInvoiceInfo] = useState({});
    const [revolut, setRevolut] = useState(false);
    const [publicId, setPublicId] = useState("");
    const [revolutId, setRevolutId] = useState("");
    const [amount, setAmount] = useState();
    const [saleId, setSaleId] = useState();
    const [appointId, setAppointId] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [confirm, setConfirm] = useState(true);
    const [paymentType, setPaymentType] = useState();
    const { id, appt_id } = useParams();
    // const isValidApptId = !isNaN(appt_id) && parseInt(appt_id) >= 0;
    const { data, loading } = useQuery(INVOICE, {
        variables: {
            sale_id: parseInt(id),
            appt_id: appt_id? parseInt(appt_id) : 0
        }
    })
    const [makePayment] = useMutation(MAKE_PAYMENT);
    //console.log("invoiceInfo?.payment_status", invoiceInfo?.payment_status)
    //--------------get payment type from url-------------------
    const params = new URL(document.location).searchParams;
    const type = params.get("payment_type")
    //console.log("type", type);

    //---------------------Revolut public ID-------------------
    const [revolutPaymentPublicId] = useMutation(REVOLUT_PUBLIC_ID);

    const payNow = () => {
        setConfirm(true)
        makePayment({
            variables: {
                sale_id: parseInt(id),
                appt_id: parseInt(appt_id),
                payment_type: type ? type : paymentType
            }
        }).then(({ data }) => {
            if (data) {
                console.log("data", data)
                if (data.makePayment.status === 1) {
                    // console.log(data.makePayment.message, data);
                    if (type === "revolut" || paymentType === "revolut") {
                        setAmount(parseFloat(data.makePayment?.payment_amount))
                        setSaleId(data.makePayment?.sale_id);
                        setAppointId(data.makePayment?.appt_id);
                        revolutPaymentPublicId({
                            variables: {
                                amount: parseFloat(data.makePayment?.payment_amount),
                                currency: "EUR"
                            }
                        }).then(({ data }) => {
                            //console.log("public key", data?.revolutPaymentPublicId?.id);
                            setRevolut(true);
                            setPublicId(data?.revolutPaymentPublicId?.public_id);
                            setRevolutId(data?.revolutPaymentPublicId?.id);
                        }).catch(e => {
                            setConfirm(false);
                            console.log("error", e)
                        })
                    }
                    if (type === "stripe" || paymentType === "stripe") {
                        console.log("data.makePayment.payment_url", data.makePayment.payment_url)
                        window.location.replace(data.makePayment.payment_url);
                    }
                } else {
                    toast.error(data.makePayment.message);
                    setConfirm(false);
                }
            }
        }).catch(e => {
            console.log(e);
            toast.error("Internal server error!");
            setConfirm(false);
        })
    }

    useEffect(() => {
        if (data) {
            setInvoiceInfo(data.invoiceDetail);
            setCountry(data.invoiceDetail?.business_info?.country)
            // console.log("data.invoiceDetail", data.invoiceDetail);
            var x = 0;
            if (data.invoiceDetail?.sale_detail) {
                data.invoiceDetail?.sale_detail?.map((item) => (
                    x = x + item.unit_price * item.product_qty
                ))
                setSubTotal(x)
            }
            // console.log(subTotal)
        }
        if (type) {
            setConfirm(false);
        }
    }, [data, type]);

    const parser = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    //----------get currency----------
    const c = GetCurrency(country)
    useEffect(() => {
        if (country && c) {
            setcurrency(c);
        }
    }, [country, c]);
    return (
        <>
            <MetaGenerator
                title="Invoice"
            /> 
            <div className="container my-5">
                {loading &&
                    <div className='logo container pt-140 pb-60 divLoader' style={{ height: "100vh" }}>
                        <p><img src={logo} alt="Chuzeday" /></p>
                        <p>Loading...</p>
                    </div>
                }
                {data &&
                    <>
                        {
                            invoiceInfo ?
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body p-0">
                                                    <div className="row p-5">
                                                        <div className="col-md-6 logo">
                                                            <img src={(invoiceInfo?.business_info?.thumbnail ? `${imageBaseURL}${invoiceInfo?.business_info?.thumbnail}` : demoService)}
                                                                alt={invoiceInfo?.business_info?.thumbnail} />
                                                        </div>

                                                        <div className="col-md-6 text-right">
                                                            <p className="font-weight-bold mb-1">{(invoiceInfo?.inv_pre && invoiceInfo?.inv_no) ? `Invoice ${invoiceInfo?.inv_pre}${invoiceInfo?.inv_no}` : ""}</p>
                                                            <p className="text-muted">{moment.unix(invoiceInfo?.created_at).format('MMMM Do YYYY, h:mm a')}</p>
                                                        </div>
                                                    </div>

                                                    <hr className="my-5" />

                                                    <div className="row pb-5 p-5">
                                                        <div className="col-md-6">
                                                            <p className="font-weight-bold mb-4">Client Information</p>
                                                            <p className="mb-1 text-capitalize">{invoiceInfo?.buyer_name}</p>
                                                            <p>{invoiceInfo?.buyer_info?.mobile ? invoiceInfo?.buyer_info?.mobile : ""}</p>
                                                            <p>{invoiceInfo?.buyer_info?.email ? invoiceInfo?.buyer_info?.email : ""}</p>
                                                            <p>{invoiceInfo?.buyer_info?.address ? invoiceInfo?.buyer_info?.address : ""}</p>
                                                        </div>

                                                        <div className="col-md-6 text-right text-capitalize">
                                                            <p className="font-weight-bold mb-4">Payment Details</p>
                                                            <p className="mb-1"><span className="text-muted">VAT ID: </span>
                                                                {
                                                                    invoiceInfo?.business_info?.vat_number ?
                                                                        invoiceInfo?.business_info?.vat_number :
                                                                        "N/A"
                                                                }
                                                            </p>
                                                            {
                                                                invoiceInfo?.payment_type && parser(invoiceInfo?.payment_type) &&
                                                                <p className="mb-1"><span className="text-muted">Payment Type: </span>
                                                                    {
                                                                        JSON.parse(invoiceInfo?.payment_type)?.map((x, i) => <span className='comma' key={i}>{x}</span>)
                                                                    }
                                                                </p>
                                                            }
                                                            <p className="mb-1"><span className="text-muted">Payment Status: </span> {invoiceInfo?.payment_status === 'Upfront' ? "Partially paid" : invoiceInfo?.payment_status}</p>
                                                            <p className="mb-1 text-capitalize"><span className="text-muted">Name: </span> {invoiceInfo?.business_info?.name}</p>
                                                        </div>
                                                    </div>

                                                    <div className="row p-5">
                                                        <div className="col-md-12">
                                                            <table className="table font-chzday d-md-table d-none">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="border-0 text-uppercase small font-weight-bold">ID</th>
                                                                        <th className="border-0 text-uppercase small font-weight-bold">Name</th>
                                                                        <th className="border-0 text-uppercase small font-weight-bold">Quantity</th>
                                                                        <th className="border-0 text-uppercase small font-weight-bold">Price</th>
                                                                        <th className="border-0 text-uppercase small font-weight-bold">Discount</th>
                                                                        <th className="border-0 text-uppercase small font-weight-bold">Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        invoiceInfo?.sale_detail?.map((item) => (
                                                                            <tr key={item.id}>
                                                                                <td>{item.id}</td>
                                                                                <td className='text-capitalize'>{item.product_name}</td>
                                                                                <td>{item.product_qty}</td>
                                                                                <td>{currency}{item.unit_price}</td>
                                                                                <td>{currency}{item.discount * item.product_qty}</td>
                                                                                <td>{currency}{(item.unit_price * item.product_qty) - (item.discount * item.product_qty)}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            <table className="table font-chzday d-md-none d-block">
                                                                <tbody>
                                                                    {
                                                                        invoiceInfo?.sale_detail?.map((item) => (
                                                                            <Fragment key={item.id}>
                                                                                <tr>
                                                                                    <th className="border-0 text-uppercase small font-weight-bold">ID:</th>
                                                                                    <td>{item.id}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th className="border-0 text-uppercase small font-weight-bold">Name:</th>
                                                                                    <td>{item.product_name}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th className="border-0 text-uppercase small font-weight-bold">Quantity:</th>
                                                                                    <td>{item.product_qty}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th className="border-0 text-uppercase small font-weight-bold">Price:</th>
                                                                                    <td>{currency}{item.unit_price}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th className="border-0 text-uppercase small font-weight-bold">Discount:</th>
                                                                                    <td>{currency}{item.discount * item.product_qty}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th className="border-0 text-uppercase small font-weight-bold">Total:</th>
                                                                                    <td>{currency}{(item.unit_price * item.product_qty) - (item.discount * item.product_qty)}</td>
                                                                                </tr>

                                                                            </Fragment>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="chuzy-bg text-white p-4 rounded col-10 mx-auto d-lg-flex justify-content-around">
                                                        <div className="py-3 px-5 text-right">
                                                            <div className="mb-2">Sub - Total amount</div>
                                                            <div className="h2 font-weight-light">{currency}{subTotal}</div>
                                                        </div>
                                                        <div className="py-3 px-5 text-right">
                                                            <div className="mb-2">Discount</div>
                                                            <div className="h2 font-weight-light">{currency}{invoiceInfo?.discount + invoiceInfo.s_discount}</div>
                                                        </div>
                                                        <div className="py-3 px-5 text-right">
                                                            <div className="mb-2">Paid</div>
                                                            <div className="h2 font-weight-light">{currency}{invoiceInfo?.payment_amount}</div>
                                                        </div>
                                                        <div className="py-3 px-5 text-right">
                                                            <div className="mb-2">{invoiceInfo?.payment_amount === 0 ? "Payable (Deposit)" : "Dues"}</div>
                                                            <div className="h2 font-weight-light">{currency}{invoiceInfo?.due_amount}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (invoiceInfo?.payment_status === "Unpaid" || invoiceInfo?.payment_status === "Upfront") &&
                                        <>
                                            {
                                                !type &&
                                                <div className='d-flex justify-content-center pt-5 p-way'>
                                                    <div className='mb-5 me-5'>
                                                        <input className='form-check-input' type="radio" id="stripe" name="p_way"
                                                            onChange={() => { setPaymentType("stripe"); setConfirm(false); }} />
                                                        <label className='ms-3' htmlFor="stripe"><img src={Stripe} alt="stripe" /></label>
                                                    </div>
                                                    <div className='d-flex justify-content-center'>
                                                        <input className='form-check-input' type="radio" id="revolut" name="p_way"
                                                            onChange={() => { setPaymentType("revolut"); setConfirm(false); }} />
                                                        <label className='ms-3' htmlFor="revolut">
                                                            <img className="revolut" src={Revolut} alt="voucher" />
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                            <div className='col-md-4 mx-auto venuItem text-center'>
                                                <button
                                                    disabled={confirm}
                                                    className='btn venuBtn btn-lg my-4'
                                                    onClick={() => { payNow() }}
                                                >
                                                    Make Payment
                                                </button>
                                            </div>
                                        </>
                                    }
                                </> :
                                <h2 className='inv-error text-center'>Sorry couldn't find the invoice!</h2>
                        }

                    </>
                }
            </div>
            {

                revolutId && saleId &&
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={revolut}
                    onHide={() => setRevolut(false)}
                >
                    <div className='p-3'>
                        <RevolutPayment
                            order={publicId}
                            amount={amount}
                            saleId={saleId}
                            appointId={appointId}
                            revolutId={revolutId}
                        />
                    </div>
                </Modal>
            }
        </>

    )
}

export default Invoice
